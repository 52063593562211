<template>
  <div>
    <b-button variant="success" @click="openModal()">ثبت تنوع</b-button>
    <b-card title="تنوع‌ها" class="mt-3 varieties-list">
      <b-table responsive striped hover :fields="table_fields" :items="product.varieties">

        <template v-slot:cell(created_at)="data">{{ data.item.created_at | persianDate }}</template>
        <template v-slot:cell(fullTitle)="data">
          <p v-if="data.item.product.variety == 'weight' ">{{ data.item.title }}</p>
          <p v-else-if="data.item.product.variety == 'title'">{{ data.item.title }}</p>
          <p v-else>{{ data.item.fullTitle }}</p>
        </template>
        <!-- <template v-slot:cell(color)="data" >
            <p v-if="data.item.color">{{ data.item.color.name }}</p>
          </template>
          <template v-slot:cell(size)="data" >
            <p v-if="data.item.size">{{ data.item.size.name }}</p>
          </template> -->
        <template v-slot:cell(default)="data">
          <i v-if="data.item.default == '1'" class="fas fa-star" style="color: gold;"></i>
          <i v-else-if="data.item.default == '0'" class="fas fa-star"></i>
        </template>
        <template v-slot:cell(gift)="data">

          <div v-if="data.item.gift">
            <i class="fas fa-gift" style="color:gold;"></i>
          </div>

        </template>
        <template v-slot:cell(status)="data">
          <span class="badge badge-success" v-if="data.item.status == 'available'">موجود</span>
          <span class="badge badge-light" v-else-if="data.item.status == 'unavailable'">ناموجود</span>
          <span class="badge badge-warning" v-else-if="data.item.status == 'inactive'">غیرفعال</span>
          <span class="badge badge-info" v-else-if="data.item.status == 'soon'">به زودی</span>
        </template>
        <template v-slot:cell(number)="data">
          <!-- <b-dropdown :text="data.item.in_storage ? data.item.in_storage.toString() : '0'" variant="outline-primary" size="sm" @click="loadStore(data.item.id)">
            <b-dropdown-text>
              <div class="row">
                <div class="col-6">
                  <div class="text-center p-2 btn btn-block" @click="setIncrement(data.index)">
                    <i class="fa fa-plus"></i>
                  </div>
                </div>
                <div class="col-6">
                  <div class="text-center p-2 btn btn-block" @click="setDecrement(data.index)">
                    <i class="fa fa-minus"></i>
                  </div>
                </div>
              </div>
            </b-dropdown-text>
          </b-dropdown> -->
          <b-button variant="outline-primary" @click="loadStore(data.index)" class="py-0" :disabled="disabledBtn">
            {{data.item.in_storage ? data.item.in_storage : 0}}</b-button>


        </template>
        <template v-slot:cell(gallery)="data">
          <button class="btn btn-success" @click="showImage(data.index)">
            <i class="fa fa-edit"></i>
          </button>
        </template>
        <template v-slot:cell(price)="data">
          {{ data.item.price ? numberFormat(data.item.price) : ''  }}
        </template>
        <template v-slot:cell(minimumOrder)="data">
          <button class="btn btn-info" @click="minimumOrder(data.index, product.varieties)">
            <i class="fa fa-edit"></i>
          </button>
        </template>
        <template v-slot:cell(edit)="data">
          <button class="btn btn-primary" @click="editItem(data.index)">
            <i class="fa fa-edit"></i>
          </button>
        </template>
        <template v-slot:cell(delete)="data">
          <button class="btn btn-danger" @click="deleteItem(data.item.id)">
            <i class="fa fa-close"></i>
          </button>
        </template>
      </b-table>
      <div class="alert alert-danger text-right" v-if="product.varieties == ''">
        <span class="text-right">موردی یافت نشد ! </span>
      </div>
    </b-card>

    <b-modal id="showImage" :title="' تصاویر ' + itemGalleries.fullTitle" size="lg" hide-footer>
      <form id="new-image" @submit.prevent="newImage">
        <div class="d-flex justify-content-start">
          <b-form-group>
            <b-form-file name="image" accept="image/*"></b-form-file>
          </b-form-group>
          <div>
            <input type="hidden" name="variety_id" :value="itemGalleries.id">
            <button class="btn btn-success mr-2" type="submit" :disabled="disabled">ثبت</button>
          </div>
        </div>
      </form>

      <div class="row mx-2">
        <div class="col-4 px-2 my-2" v-for="(img, index) in itemGalleries.galleries" :key="index">
          <a target="_blank" class="w-100 h-90 d-block position-relative">
            <img :src="img.image">
            <b-button variant="danger" size="sm" class="position-absolute rounded-circle "
              style="left: -10px; top: -10px; z-index: 2;" @click="deleteImage(index, varieties)">
              <i class="fa fa-close"></i>
            </b-button>
          </a>
        </div>
      </div>
      <div class="clearfix">
        <!-- <button class="btn btn-primary float-right">ثبت</button> -->
        <b-button class="float-left" variant="white" type="button" @click="$root.$emit('bv::hide::modal', 'showImage')">
          بستن</b-button>
      </div>
    </b-modal>

    <b-modal id="increment" title="اضافه کردن به انبار" hide-footer>
      <form id="increase" @submit.prevent="increaseQuantity">
        <div>
          <label for="seller"> فروشنده <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
          <multiselect @input="showPrice()" id="seller" class="form-group" :options="sellers" label="name" track-by="id"
            v-model="selected_seller" placeholder="" :searchable="true" :close-on-select="true" :show-labels="false">
          </multiselect>
          <input type="hidden" name="seller_id" :value="selected_seller != null ? selected_seller.id : '' ">
        </div>
        <div v-if="product.category && product.category.unit == 'number'">
          <div class="form-group">
            <label for="in_storage"> تعداد در انبار</label>
            <input class="form-control" id="number" name="number" v-model="number">
          </div>
        </div>
        <div v-if="show_price">
          <div class="form-group">
            <label for="increment-price"> قیمت</label>
            <input class="form-control" id="increment-price" title="price" v-model="varietyPrice">
          </div>
        </div>
        <div class="form-group">
          <label for="description">توضیحات </label>
          <b-form-textarea id="description" name="description" data-required></b-form-textarea>
        </div>
        <div class="clearfix">
          <button class="btn btn-success float-right" type="submit" :disabled="disabled">ثبت</button>
          <b-button class="float-left" variant="white" type="button"
            @click="$root.$emit('bv::hide::modal', 'increment')">بستن</b-button>
        </div>
      </form>
    </b-modal>

    <b-modal id="decrement" title="کم کردن از انبار" hide-footer>
      <form id="decrease" @submit.prevent="decreaseQuantity">
        <div>
          <label for="seller"> فروشنده <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
          <multiselect id="seller" class="form-group" :options="sellers" label="name" track-by="id"
            v-model="selected_seller" placeholder="" :searchable="true" :close-on-select="true" :show-labels="false">
          </multiselect>
          <input type="hidden" name="seller_id" :value="selected_seller != null ? selected_seller.id : '' ">
        </div>
        <div v-if="product.category && product.category.unit == 'number'">
          <div class="form-group">
            <label for="in_storage"> تعداد در انبار</label>
            <input class="form-control" id="number" name="number" v-model="number">
          </div>
        </div>
        <div class="form-group">
          <label for="description">توضیحات </label>
          <b-form-textarea id="description" name="description" data-required>
          </b-form-textarea>
        </div>
        <div class="clearfix">
          <button class="btn btn-success float-right" type="submit" :disabled="disabled">ثبت</button>
          <b-button class="float-left" variant="white" type="button"
            @click="$root.$emit('bv::hide::modal', 'decrement')">بستن</b-button>
        </div>
      </form>
    </b-modal>

    <b-modal id="new-variety" title="ثبت تنوع" hide-footer size="lg">
      <div>
        <form id="add-variety" @submit.prevent="newVariety">
          <div class="row" v-if="product.category">
            <div class="col-md-6" v-if="product.category.variety == 'title'">
              <b-form-group>
                <label for="title"> عنوان </label>
                <b-form-input id="title" name="title"></b-form-input>
              </b-form-group>
            </div>
            <!-- <div class="col-md-12" v-if="product.category.variety== 'weight'">
                <div class="row">
                    <div class="form-group col-md-6">
                        <label for="meter ">کیلو</label>
                        <input class="form-control" id="kilo" name="title_kilo" v-model="newItem.kilo">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="cm">گرم</label>
                        <input class="form-control" id="gram" name="title_gram" v-model="newItem.gram">
                    </div>
                </div>
             </div> -->
            <!-- <div class="col-md-6">
                <b-form-group label="بارکد" label-for="barcode">
                  <b-form-input id="barcode" name="barcode"></b-form-input>
                </b-form-group>
              </div> -->

            <div class="col-6" v-if="product.category.variety == 'color-size' || product.category.variety == 'size'">
              <b-form-group label="سایز" label-for="sizes">
                <multiselect deselectLabel selectLabel selectedLabel v-model="newItem.size" :options="sizes"
                  label="name" track-by="id" placeholder></multiselect>
                <input type="hidden" name="size_id" v-if="newItem.size" :value="newItem.size.id">
                <input type="hidden" v-else>
              </b-form-group>
            </div>
            <div class="col-6" v-if="product.category.variety == 'color-size' || product.category.variety == 'color'">
              <b-form-group label="رنگ" label-for="color">
                <multiselect deselectLabel selectLabel selectedLabel v-model="newItem.color" :options="colors"
                  label="name" track-by="id" placeholder></multiselect>
                <input type="hidden" name="color_id" v-if="newItem.color" :value="newItem.color.id">
                <input type="hidden" v-else>
              </b-form-group>
            </div>

            <!-- <div class="col-6">
                <b-form-group label="هدیه" label-for="gift">
                  <multiselect
                    deselectLabel
                    selectLabel
                    selectedLabel
                    v-model="newItem.gift"
                    :options="gifts"
                    label="title"
                    track-by="id"
                    placeholder
                  ></multiselect>
                  <input type="hidden" name="gift_id" v-if="newItem.gift" :value="newItem.gift.id">
                </b-form-group>
              </div> -->

            <!-- <div class="col-6">
                    <b-form-group >
                      <label for="image"> تصویر اصلی <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
                      <b-form-file id="image" name="image" data-required></b-form-file>
                    </b-form-group>
                  </div>
                 -->

            <div class="col-md-6">
              <b-form-group label="تخفیف (درصد)" label-for="discount">
                <b-form-input id="discount" name="discount"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group>
                <label for="price"> قیمت (تومان) <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <b-form-input id="price" title="price" v-model="price"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group>
                <label for="status"> وضعیت <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <select id="status" name="status" class="form-control">
                  <b-form-select-option value="available">موجود</b-form-select-option>
                  <b-form-select-option value="unavailable">ناموجود</b-form-select-option>
                  <b-form-select-option value="inactive">غیرفعال</b-form-select-option>
                  <b-form-select-option value="soon">به زودی</b-form-select-option>
                </select>
              </b-form-group>
            </div>

            <!-- <div class="col-md-6" v-if="category.unit == 'number'">
                    <div class="form-group">
                        <label for="in_storage"> تعداد در انبار</label>
                        <input class="form-control" id="number" name="number">
                    </div>
                </div> -->
            <!-- <div class="col-md-12" v-if="category.unit == 'meter'">
                  <div class="row">
                      <div class="form-group col-md-6">
                          <label for="meter"> متر</label>
                          <input class="form-control" id="meter" name="meter" v-model="newItem.meter">
                      </div>
                      <div class="form-group col-md-6">
                          <label for="cm"> سانتی متر</label>
                          <input class="form-control" id="cm" name="cm" v-model="newItem.cm">
                      </div>
                  </div>
                </div>
                <div class="col-md-12" v-if="category.unit == 'kg'">
                   <div class="row">
                       <div class="form-group col-md-6">
                           <label for="meter ">کیلو</label>
                           <input class="form-control" id="kilo" name="kilo" v-model="newItem.kilo">
                       </div>
                       <div class="form-group col-md-6">
                           <label for="cm">گرم</label>
                           <input class="form-control" id="gram" name="gram" v-model="newItem.gram">
                       </div>
                   </div>
                </div> -->
            <div class="col-md-6">
              <div class="form-group mt-4">
                <label class="custom-switch"
                  :style="product.category.variety == 'color-size' ? 'margin-top: 25px;' : '' ">
                  <input type="checkbox" name="default" class="custom-switch-input">
                  <span class="custom-switch-indicator"></span>
                  <span class="custom-switch-description">تنوع پیش فرض</span>
                </label>
              </div>
            </div>


          </div>


          <input type="hidden" name="product_id" :value="product.id">

          <div class="clearfix">
            <button class="btn btn-success float-right" type="submit" :disabled="disabled">ثبت</button>
            <b-button class="float-left" variant="white" type="button"
              @click="$root.$emit('bv::hide::modal', 'new-variety')">بستن</b-button>
          </div>
        </form>
      </div>
    </b-modal>
    <b-modal id="minimum-order" hide-footer title="میزان حداقل خرید مشتری">
      <div class="alert alert-danger" v-if="minimum_order.minimum != ''">
        <p>
          <i class="fas fa-exclamation"></i>
          میزان حداقل سفارش مشتری برای این تنوع {{ minimum_order.minimum }} است.
        </p>
      </div>
      <div v-if="minimum_order.product">
        <form :action="'/api/admin/set/minimum/order/' + minimum_order.id" class="js-submit-form" method="POST"
          data-on-success="minOrder">
          <div class="col-md-6" v-if="minimum_order.product.unit == 'number'">
            <div class="form-group">
              <label for="in_storage"> تعداد :</label>
              <input class="form-control" id="number" name="number">
            </div>
          </div>
          <!-- <div class="col-md-12" v-if="minimum_order.product.unit == 'meter'">
              <div class="row">
                  <div class="form-group col-md-6">
                      <label for="meter"> متر</label>
                      <input class="form-control" id="meter" name="meter" >
                  </div>
                  <div class="form-group col-md-6">
                      <label for="cm"> سانتی متر</label>
                      <input class="form-control" id="cm" name="cm" >
                  </div>
              </div>
            </div>
            <div class="col-md-12" v-if="minimum_order.product.unit == 'kg'">
              <div class="row">
                  <div class="form-group col-md-6">
                      <label for="meter ">کیلو</label>
                      <input class="form-control" id="kilo" name="kilo">
                  </div>
                  <div class="form-group col-md-6">
                      <label for="cm">گرم</label>
                      <input class="form-control" id="gram" name="gram" >
                  </div>
              </div>
           </div> -->

          <button class="btn btn-success">ثبت</button>
        </form>
      </div>
    </b-modal>
    <b-modal id="edit-modal" title="ویرایش تنوع" hide-footer size="lg">
      <div v-if="edit.id">
        <form id="edit-variety" @submit.prevent="varietyEdited">

          <div class="row" v-if="product.category">
            <div class="col-md-6" v-if="product.category.variety == 'title'">
              <b-form-group label="عنوان" label-for="title">
                <b-form-input id="title" name="title" v-model="edit.title"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-12" v-if="product.category.variety== 'weight'">
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="meter ">کیلو</label>
                  <input class="form-control" id="kilo" name="title_kilo" v-model="edit.kilo">
                </div>
                <div class="form-group col-md-6">
                  <label for="cm">گرم</label>
                  <input class="form-control" id="gram" name="title_gram" v-model="edit.gram">
                </div>
              </div>
            </div>
            <!-- <div class="col-md-6">
                <b-form-group label="بارکد" label-for="barcode">
                  <b-form-input id="barcode" name="barcode" v-model="edit.barcode" ></b-form-input>
                </b-form-group>
              </div> -->
            <div class="col-6" v-if="product.category.variety == 'color-size' || product.category.variety == 'size'">
              <b-form-group label="سایز" label-for="sizes">
                <multiselect deselectLabel selectLabel selectedLabel v-model="edit.size" :options="sizes" label="name"
                  track-by="id" placeholder></multiselect>
                <input type="hidden" name="size_id" v-if="edit.size" :value="edit.size.id">
                <input type="hidden" v-else>
              </b-form-group>
            </div>
            <div class="col-6" v-if="product.category.variety == 'color-size' || product.category.variety == 'color'">
              <b-form-group label="رنگ" label-for="color">
                <multiselect deselectLabel selectLabel selectedLabel v-model="edit.color" :options="colors" label="name"
                  track-by="id" placeholder></multiselect>
                <input type="hidden" name="color_id" v-if="edit.color" :value="edit.color.id">
                <input type="hidden" v-else>
              </b-form-group>
            </div>
            <!-- <div class="col-6">
                <b-form-group label="هدیه" label-for="gift">
                  <multiselect
                    deselectLabel
                    selectLabel
                    selectedLabel
                    v-model="edit.gift"
                    :options="gifts"
                    label="title"
                    track-by="id"
                    placeholder
                  ></multiselect>
                  <input type="hidden" name="gift_id" v-if="edit.gift" :value="edit.gift.id">
                </b-form-group>
              </div> -->
            <!-- <div class="col-6">
                <label for="edit-image">
                  تصویر اصلی جدید
                  <a :href="edit.image" target="_blank">(مشاهده)</a>
                </label>
                <b-form-file id="image" name="image"></b-form-file>
              </div> -->
            <div class="col-md-6">
              <b-form-group label="تخفیف (درصد)" label-for="discount">
                <b-form-input id="discount" name="discount" v-model="edit.discount"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group>
                <label for="price"> قیمت (تومان) <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <b-form-input id="edit-price" title="price" v-model="price"></b-form-input>
              </b-form-group>
            </div>

            <div class="col-md-6">
              <b-form-group>
                <label for="status"> وضعیت <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <select id="status" name="status" class="form-control" v-model="edit.status">
                  <b-form-select-option value="available">موجود</b-form-select-option>
                  <b-form-select-option value="unavailable">ناموجود</b-form-select-option>
                  <b-form-select-option value="inactive">غیرفعال</b-form-select-option>
                  <b-form-select-option value="soon">به زودی</b-form-select-option>
                </select>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="custom-switch"
                  :style="product.category.variety == 'color-size' ? 'margin-top: 25px;' : '' ">
                  <input type="checkbox" name="default" class="custom-switch-input" v-model="edit.default">
                  <span class="custom-switch-indicator"></span>
                  <span class="custom-switch-description">تنوع پیش فرض</span>
                </label>
              </div>
            </div>

          </div>
          <input type="hidden" name="product_id" :value="edit.product_id">
          <input type="hidden" value="PUT" name="_method">
          <div class="clearfix">
            <button class="btn btn-primary float-right" type="submit" :disabled="disabled">ویرایش</button>
            <b-button class="float-left" variant="white" type="button"
              @click="$root.$emit('bv::hide::modal', 'edit-item')">بستن</b-button>
          </div>
        </form>
      </div>
    </b-modal>
    <b-modal id="store-modal" :title="variety.fullTitle + ' - موجودی انبار :' + variety.in_storage" hide-footer
      size="lg">
      <div class="d-flex justify-content-between">
        <h5>لیست فروشنده ها :</h5>
        <div class="d-flex">
          <div class="text-center btn btn-success" @click="setIncrement()">
            <i class="fa fa-plus"></i>
          </div>
          <div class="text-center mt-0 mr-2 btn btn-danger" @click="setDecrement()">
            <i class="fa fa-minus"></i>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <b-table responsive striped hover :fields="seller_fields" :items="store">
          <template v-slot:cell(id)="data">
            {{data.item.seller ? data.item.seller.id : ''}}
          </template>
          <template v-slot:cell(price)="data">
            {{data.item.price ? numberFormat(data.item.price) : ''}}
          </template>
          <template v-slot:cell(name)="data">
            <span v-if="data.item.new"> {{data.item.seller ? data.item.seller.name : ''}}</span>
            <span v-else>
              {{data.item.seller ? data.item.seller.name + ' ' + data.item.seller.last_name + ' - نام شرکت : ' + data.item.seller.company_name : ''}}</span>
          </template>
          <template v-slot:cell(edit)="data">
            <button class="btn btn-primary" @click="editPrice(data.index)">
              <i class="fa fa-edit"></i>
            </button>
          </template>
        </b-table>
        <div class="alert alert-danger text-right" v-if="store == ''">
          <span class="text-right">موردی یافت نشد ! </span>
        </div>
      </div>
      <div class="clearfix">
        <b-button class="float-left" variant="white" type="button"
          @click="$root.$emit('bv::hide::modal', 'store-modal')">بستن</b-button>
      </div>
    </b-modal>
    <b-modal id="edit-price-modal" title="ویرایش قیمت" hide-footer>
      <form id="price-edited" @submit.prevent="priceEdited">
        <b-form-group>
          <label for="edit-price-store">قیمت <i class="fas fa-asterisk text-danger"
              style="font-size: 0.5rem;"></i></label>
          <b-form-input id="edit-price-store" title="price" v-model="varietyPrice"></b-form-input>
        </b-form-group>
        <input type="hidden" name="seller_id" :value="edit_price.seller_id">
        <div class="clearfix">
          <button class="btn btn-success float-right" type="submit" :disabled="disabled">ثبت</button>
          <b-button class="float-left" variant="white" type="button"
            @click="$root.$emit('bv::hide::modal', 'edit-price-modal')">بستن</b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
  export default {
    props: ['product'],
    data() {
      return {
        url: '/api/admin/varieties',
        title: 'تنوع ها',
        newItem: {
          size: null,
          color: null,
          gift: null,
          cm: null,
          meter: null,
          kilo: null,
          gram: null,
          number: null,
        },
        sizes: [],
        colors: [],
        gifts: [],
        number: 0,
        itemGalleries: {},
        minimum_order: {},
        table_fields: [{
            key: 'id',
            label: 'شناسه '
          },
          {
            key: 'fullTitle',
            label: 'عنوان'
          },
          {
            key: 'price',
            label: 'قیمت (تومان)'
          },
          {
            key: 'status',
            label: 'وضعیت'
          },
          {
            key: 'gallery',
            label: 'نمایش تصاویر'
          },
          {
            key: 'number',
            label: 'تعداد در انبار'
          },
          {
            key: 'default',
            label: 'تنوع پیش فرض'
          },
          {
            key: 'discount',
            label: 'تخفیف (درصد)'
          },
          {
            key: 'created_at',
            label: 'تاریخ ثبت'
          },
          {
            key: 'edit',
            label: 'ویرایش '
          },
          {
            key: 'delete',
            label: 'حذف'
          }
        ],
        index: '',
        disabled: false,
        edit: {},
        price: '',
        sellers: [],
        selected_seller: null,
        variety: {},
        store: [],
        disabledBtn: false,
        seller_fields: [{
            key: 'id',
            label: 'شناسه'
          },
          {
            key: 'name',
            label: 'فروشنده'
          },
          {
            key: 'in_stock',
            label: 'تعداد در انبار'
          },
          {
            key: 'price',
            label: 'قیمت'
          },
          {
            key: 'edit',
            label: 'ویرایش'
          },
        ],
        show_price: false,
        varietyPrice: 0,
        edit_price: {},
      }
    },
    computed: {
      varieties() {
        return this.$props.product.varieties
      },
      category() {
        return this.$props.product.category;
      },
    },
    watch: {
      price: function (newValue) {
        let englishNumber = this.convertToEnglishNumber(newValue);
        const result = englishNumber
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.price = result;
      },
      varietyPrice: function (newValue) {
        let englishNumber = this.convertToEnglishNumber(newValue);
        const result = englishNumber
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.varietyPrice = result;
      },
    },
    methods: {
      openModal() {
        this.newItem = {
          size: null,
          color: null,
          number: null,
        }
        this.$root.$emit('bv::show::modal', 'new-variety')
      },
      numberFormat(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      convertToEnglishNumber(input) {
        var persianDigits = "۰۱۲۳۴۵۶۷۸۹";
        input = "" + input + "";
        return input.replace(/[\u06F0-\u06F9]/g, function (m) {
          return persianDigits.indexOf(m);
        });
      },
      editItem(index) {
        this.edit = {}
        let item = window.clone(this.product.varieties[index])
        item.index = index
        this.edit = item
        this.price = this.edit.price
        this.$root.$emit('bv::show::modal', 'edit-modal')
      },
      editPrice(index) {
        this.edit_price = {}
        let item = window.clone(this.store[index])
        item.index = index
        this.edit_price = item
        this.varietyPrice = this.edit_price.price
        this.$root.$emit('bv::show::modal', 'edit-price-modal')
      },
      priceEdited() {
        this.disabled = true
        let form = document.getElementById('price-edited')
        let formData = new FormData(form)
        const price = document.getElementById('edit-price-store')

        if (price != null) {
          let org_price = this.varietyPrice <= 999 ? this.varietyPrice : this.varietyPrice.replace(/\D/g, "");
          formData.append(`${price.title}`, org_price);
        }

        this.$axios.post(this.$root.baseUrl + `/api/admin/change-price/store/${this.variety.id}`, formData)
          .then(response => {
            this.$root.success_notification(response.data.message)
            this.store[this.edit_price.index].price = this.varietyPrice
            this.$root.$emit('bv::hide::modal', 'edit-price-modal')
          })
          .catch(error => {
            this.$root.error_notification(error)
          })
          .finally(() => {
            this.disabled = false
          })
      },
      newVariety() {
        this.disabled = true
        let form = document.getElementById('add-variety')
        let formData = new FormData(form)
        const price = document.getElementById('price')

        if (price != null) {
          let org_price = this.price <= 999 ? this.price : this.price.replace(/\D/g, "");
          formData.append(`${price.title}`, org_price);
        }

        this.$axios.post(this.$root.baseUrl + this.url, formData)
          .then(response => {
            this.$root.success_notification(response.data.message)
            this.$props.product.varieties.unshift(response.data.data.variety)
            this.$root.$emit('bv::hide::modal', 'new-variety')
            this.price = 0
          })
          .catch(error => {
            this.$root.error_notification(error)
          })
          .finally(() => {
            this.disabled = false
          })
      },
      varietyEdited() {
        this.disabled = true
        let form = document.getElementById('edit-variety')
        let formData = new FormData(form)
        const price = document.getElementById('edit-price')
        if (price != null) {
          let org_price = this.price <= 999 ? this.price : this.price.replace(/\D/g, "");
          formData.append(`${price.title}`, org_price);
        }

        this.$axios.post(this.$root.baseUrl + this.url + `/${this.edit.id}`, formData)
          .then(response => {
            this.$root.success_notification(response.data.message)
            this.$root.$set(this.product.varieties, this.edit.index, response.data.data.variety)
            this.$root.$emit('bv::hide::modal', 'edit-modal')
            this.price = 0
          })
          .catch(error => {
            this.$root.error_notification(error)
          })
          .finally(() => {
            this.disabled = false
          })
      },
      loadStore(index) {
        this.loadseller()
        this.disabledBtn = true
        let item = window.clone(this.product.varieties[index])
        item.index = index
        this.variety = item
        this.$axios.get(this.$root.baseUrl + `/api/admin/varieties/${this.variety.id}/store?all=true`)
          .then(response => {
            this.store = response.data.data
            this.$root.$emit('bv::show::modal', 'store-modal')
            this.disabledBtn = false
          })
      },
      setIncrement() {
        this.number = 0
        this.varietyPrice = 0
        this.selected_seller = ''
        this.$root.$emit('bv::show::modal', 'increment')
      },
      setDecrement() {
        this.number = 0
        this.selected_seller = ''
        this.$root.$emit('bv::show::modal', 'decrement')
      },
      decreaseQuantity() {
        this.disabled = true
        let form = document.getElementById('decrease')
        let formData = new FormData(form)
        this.$axios.post(this.$root.baseUrl + '/api/admin/decrement/store/' + this.variety.id, formData)
          .then(response => {
            this.$root.success_notification(response.data.message)
            this.$root.$emit('bv::hide::modal', 'decrement')
            this.variety.in_storage = this.variety.in_storage - +this.number
            this.product.varieties[this.variety.index].in_storage = this.product.varieties[this.variety.index]
              .in_storage - +this.number

            this.store.filter(item => item.seller_id == this.selected_seller.id ? (item.in_stock = item.in_stock - +
              this.number) : '')
            // let oldItem = false
            // this.store.filter(item => item.seller_id == this.selected_seller.id ? oldItem = true : '')
            // if (oldItem) {
            //   this.store.filter(item => item.seller_id == this.selected_seller.id ? (item.in_stock = item.in_stock - +
            //     this.number) : '')
            // } else {
            //   this.store.unshift({
            //     seller: this.selected_seller,
            //     seller_id: this.selected_seller.id,
            //     in_stock: +this.number,
            //     new: true
            //   })
            // }
          })
          .catch(error => {
            this.$root.error_notification(error)
          })
          .finally(() => {
            this.disabled = false
          })
      },
      increaseQuantity() {
        this.disabled = true
        let form = document.getElementById('increase')
        let formData = new FormData(form)
        if (this.show_price) {
          const price = document.getElementById('increment-price')

          if (price != null) {
            let org_price = this.varietyPrice <= 999 ? this.varietyPrice : this.varietyPrice.replace(/\D/g, "");
            formData.append(`${price.title}`, org_price);
          }
        }

        this.$axios.post(this.$root.baseUrl + '/api/admin/increment/store/' + this.variety.id, formData)
          .then(response => {
            this.$root.success_notification(response.data.message)
            this.$root.$emit('bv::hide::modal', 'increment')
            this.variety.in_storage = this.variety.in_storage + +this.number
            this.product.varieties[this.variety.index].in_storage = this.product.varieties[this.variety.index]
              .in_storage + +this.number
            let oldItem = false
            this.store.filter(item => item.seller_id == this.selected_seller.id ? oldItem = true : '')
            if (oldItem) {
              this.store.filter(item => item.seller_id == this.selected_seller.id ? (item.in_stock = item.in_stock + +
                this.number) : '')
            } else {
              this.store.unshift({
                seller: this.selected_seller,
                seller_id: this.selected_seller.id,
                in_stock: +this.number,
                price: this.varietyPrice,
                new: true
              })
            }
          })
          .catch(error => {
            this.$root.error_notification(error)
          })
          .finally(() => {
            this.disabled = false
          })
      },
      showPrice() {
        let oldItem = false
        this.store.filter(item => item.seller_id == this.selected_seller.id ? oldItem = true : '')
        if (oldItem) {
          this.show_price = false
        } else {
          this.show_price = true
        }
      },
      showImage(index) {
        const item = this.product.varieties[index]
        item.index = index
        this.itemGalleries = item
        this.$root.$emit('bv::show::modal', 'showImage')
      },
      newImage() {
        this.disabled = true
        let form = document.getElementById('new-image')
        let formData = new FormData(form)
        this.$axios.post(this.$root.baseUrl + '/api/admin/galleries/' + this.itemGalleries.product_id, formData)
          .then(response => {
            this.$root.success_notification(response.data.message)
            this.product.varieties[this.itemGalleries.index].galleries.push(response.data.data)
          })
          .catch(error => {
            this.$root.error_notification(error)
          })
          .finally(() => {
            this.disabled = false
          })
      },
      minimumOrder(index) {
        const item = window.clone(this.varieties[index])
        item.index = index
        this.index = index
        this.minimum_order = item
        this.$root.$emit('bv::show::modal', 'minimum-order')
      },
      deleteImage(index) {
        window.swal({
            title: "تصویر حذف شود؟",
            text: "این عملیات غیر قابل بازگشت خواهد بود",
            icon: "warning",

            showCancelButton: true,
            showConfirmButton: true,
            dangerMode: true,
            buttons: ["خیر", "بله حذف کن"]
          })
          .then((willDelete) => {
            if (willDelete) {
              const item = this.itemGalleries.galleries[index]
              this.$axios.delete(this.$root.baseUrl + '/api/admin/galleries/' + item.id)
                .then((response) => {
                  this.$root.$delete(this.product.varieties[this.itemGalleries.index].galleries, index)
                  window.swal({
                    icon: "success",
                    showConfirmButton: true,
                    text: response.data.message,
                    button: "باشه",
                  })
                })
                .catch(error => {
                  window.swal({
                    title: 'خطا',
                    showConfirmButton: true,
                    text: error.response.data.message,
                    button: "باشه",


                  })
                })
            }
          })
          .catch(() => {

          })
      },
      deleteItem(id) {
        window.swal({
            title: "آیتم حذف شود؟",
            text: "این عملیات غیر قابل بازگشت خواهد بود",
            icon: "warning",

            showCancelButton: true,
            showConfirmButton: true,
            dangerMode: true,
            buttons: ["خیر", "بله حذف کن"]
          })
          .then((willDelete) => {
            if (willDelete) {
              this.$axios.delete(this.$root.baseUrl + this.url + `/${id}`)
                .then((response) => {
                  this.$props.product.varieties = this.product.varieties.filter(item => item.id != id)
                  window.swal({
                    icon: "success",
                    showConfirmButton: true,
                    text: response.data.message,
                    button: "باشه",
                  })
                })
                .catch(error => {
                  window.swal({
                    title: 'خطا',
                    showConfirmButton: true,
                    text: error.response.data.message,
                    button: "باشه",


                  })
                })
            }
          })
          .catch(() => {

          })
      },
      loadseller() {
        this.$axios.get(this.$root.baseUrl +
            `/api/admin/sellers?all=true&category_id=${this.$props.product.category.id}`)
          .then(response => {
            this.sellers = response.data.data
            this.sellers.map(item => item.name = item.name + ' ' + item.last_name + ' - نام شرکت : ' + item
              .company_name)
          })
      }
    },
    created() {
      this.$axios.get(this.$root.baseUrl + '/api/admin/list/sizes')
        .then(response => {
          this.sizes = response.data.data
        })
      this.$axios.get(this.$root.baseUrl + '/api/admin/list/colors')
        .then(response => {
          this.colors = response.data.data
        })


      //   window.$.get('/api/admin/gift?all=1', response => {
      //     if (response.success) {
      //       this.gifts = response.data
      //     }
      //   })

      //   window.varietyEdited = response => {
      //     if (response.success) {
      //       this.$root.$set(this.varieties, this.edit.index, response.data.variety)
      //       this.$root.$emit('bv::hide::modal', 'edit-item')
      //     }
      //   }
      //   window.minOrder = response => {
      //     if (response.success) {
      //       // this.$root.$set(this.varieties, this.edit.index, response.data.variety)
      //       const item = this.varieties[this.index]
      //       item.minimum = response.data
      //       this.$root.$emit('bv::hide::modal', 'minimum-order')
      //       this.index = ''
      //     }
      //   }

      //   window.newVariety = response => {
      //     if (response.success) {
      //       this.$emit('pushToVarieties', response.data.variety)
      //       this.$root.$emit('bv::hide::modal', 'new-variety')
      //       this.newItem.gift = null
      //       this.newItem.color = null
      //       this.newItem.size = null
      //       this.newItem.cm = null
      //       this.newItem.meter = null
      //       this.newItem.kilio = null
      //       this.newItem.gram = null
      //       this.newItem.number = null
      //     }
      //   }

      //   window.decreaseQuantity = response => {
      //     if (response.success) {
      //       this.$emit('manageVarietyQuantity', response.data)
      //       this.$root.$emit('bv::hide::modal', 'decrement')
      //     }
      //   }

      //   window.increaseQuantity = response => {
      //     if (response.success) {
      //       this.$emit('manageVarietyQuantity', response.data)
      //       this.$root.$emit('bv::hide::modal', 'increment')
      //     }
      //   }
    },
    filters: {
      persianDate(date) {
        return window.moment(date).format('HH:mm jYYYY/jMM/jDD ')
      }
    },
  }
</script>